import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol,IonButtons,IonButton,IonIcon } from '@ionic/react';
import { personCircle} from 'ionicons/icons';
import rugImage from '../assets/rugs.jpg';
import carpetImage from '../assets/carpets.jpg';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionTypes from '../store/action-types/index';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        
                        <IonTitle>Dashboard</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={()=>this.props.logout()}>
                                <IonIcon slot="start" icon={personCircle} /> LOGOUT
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <Link to="/carpet-search">
                                    <IonCard>
                                        <img src={carpetImage} alt="carpet icon" width="100%" />
                                        <IonCardHeader>
                                            <IonCardTitle>Carpet</IonCardTitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </Link>
                            </IonCol>

                            <IonCol>
                                <Link to="/rug-search">
                                    <IonCard>
                                        <img src={rugImage} alt="rug icon" width="100%" />
                                        <IonCardHeader>
                                            <IonCardTitle>Rug</IonCardTitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </Link>
                            </IonCol>
                        </IonRow>

                    </IonGrid>




                </IonContent>
            </IonPage>
        )
    }
}
const mapDispatchToProps=dispatch=>{
    return {
        logout:()=>dispatch({type:actionTypes.AUTH_LOGOUT})
    }
}
export default connect(null,mapDispatchToProps)(Dashboard);