import React from "react";
import { IonContent,IonIcon, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonList, IonNote, IonListHeader } from '@ionic/react';
import { closeCircleOutline } from "ionicons/icons";
import BackButton from '../BackButton';

const Orders = (props) => {
    const orderData = props.orderData;
    const stockCode = props.stockCode;
    const backUrl='/search-results';
    let orders = null;
    if (orderData.length > 0) {
        orders = orderData.map(el => {
            return (
                <IonList key={el.id}>
                    <IonListHeader>Order#{el.orderNumber}</IonListHeader>
                    <IonItem>
                        <IonLabel>Order date</IonLabel>
                        <h4 slot="end">{el.orderDate}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Current ETA</IonLabel>
                        <h4 slot="end">{el.currentEta}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Metres ordered</IonLabel>
                        <h4 slot="end">{el.metresOrdered}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Metres received</IonLabel>
                        <h4 slot="end">{el.metresReceived}</h4>
                    </IonItem>
                </IonList>
            )
        })
    }
    else {
        orders = (
            <ion-text color="danger">
                <p className="text-center"><IonIcon icon={closeCircleOutline}/> ORDERS NOT AVAILABLE</p>
            </ion-text>
        )
    }
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <BackButton {...props} backUrl={backUrl}/>
                    <IonTitle>{stockCode} - Orders</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                    {orders}
            </IonContent>
        </>
    )
}


export default Orders;