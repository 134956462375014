import React from "react";
import { IonContent, IonHeader,IonIcon, IonTitle, IonToolbar, IonItem, IonLabel, IonList, IonNote, IonListHeader } from '@ionic/react';
import { closeCircleOutline } from "ionicons/icons";
import BackButton from '../BackButton';

const Rolls = (props) => {
    const rollData = props.rollData;
    const stockCode = props.stockCode;
    const backUrl = '/search-results';

    let rolls = null;
    if (rollData.length > 0) {
        rolls = rollData.map(el => {
            return (
                <IonList key={el.rollId}>
                    <IonListHeader>Roll No. {el.rollId}</IonListHeader>
                    <IonItem>
                        <IonLabel>Bay number</IonLabel>
                        <h4 slot="end">{el.bayNumber}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Meters on roll</IonLabel>
                        <h4 slot="end">{el.metresOnRoll}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Supplier ID</IonLabel>
                        <h4 slot="end">{el.supplierId}</h4>
                    </IonItem>
                </IonList>
            )
        })
    }
    else {
       rolls = (
            <ion-text color="danger">
                <p className="text-center"><IonIcon icon={closeCircleOutline}/> ROLLS NOT AAVAILABLE</p>
            </ion-text>
        )
    }
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <BackButton {...props} backUrl={backUrl} />
                    <IonTitle>{stockCode} - Rolls</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {rolls}
            </IonContent>
        </>
    )
}

export default Rolls;