import Home from '../pages/Home';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import NoMatch from '../pages/NoMatch';
import CarpetSearch from '../pages/CarpetSearch';
import SearchResults from '../pages/SearchResults';
import StockDetails from '../pages/StockDetails';
import RugSearch from '../pages/RugSearch';
import RugSearchResults from '../pages/RugSearchResults'
import RugDetails from '../pages/RugDetails';
import RugBay from '../pages/RugBay';

const routes = [
  {
    path: '/',
    exact: true,
    auth: true,
    component: Dashboard,
    fallback: Login,
  },
  {
    path: '/carpet-search',
    exact: true,
    auth: true,
    component: CarpetSearch,
    fallback: Login
  },
  {
    path: '/rug-search',
    exact: true,
    auth: true,
    component: RugSearch,
    fallback: Login
  },
  {
    path: '/stock-details/:curTab/:state/:stockCode',
    exact: true,
    auth: true,
    component: StockDetails,
    fallback: Login
  },
  {
    path: '/rug-details/:state/:stockCode',
    exact: true,
    auth: true,
    component: RugDetails,
    fallback: Login
  },
  {
    path: '/rug-bay/:state/:stockCode',
    exact: true,
    auth: true,
    component: RugBay,
    fallback: Login
  },
  {
    path: '/search-results',
    exact: true,
    auth: true,
    component: SearchResults,
    fallback: Login
  },
  {
    path: '/rug-search-results',
    exact: true,
    auth: true,
    component: RugSearchResults,
    fallback: Login
  },
  {
    path: '/login',
    exact: true,
    auth: false,
    component: Login,
  },
  
  {
    path: '',
    exact: false,
    auth: false,
    component: Login,
  },
];

export default routes;
