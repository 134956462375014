import React, { Component } from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonButton, IonCard, IonAlert, IonLoading, IonGrid, IonRow, IonCol } from '@ionic/react';
import PropTypes from 'prop-types';
import logo from '../assets/logo.png';
import Http from '../Http';
import { connect } from 'react-redux';
import AuthService from '../services';
import { Link, Redirect } from 'react-router-dom';
import ReeValidate from 'ree-validate';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            response: {
                error: false,
                message: ''
            }
        }

        this.validator = new ReeValidate({
            username: 'required',
            password: 'required',
        });
      
    }

    login = (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        const credentials = {
            username,
            password,
        };

        // Set response state back to default.
        this.setState({ response: { error: false, message: '' } });

        this.validator.validateAll(credentials).then((success) => {
            if (success) {
                this.setState({ loading: true });
                this.submit(credentials);
            }
        });
    }

    submit(credentials) {
        const { dispatch } = this.props;
        dispatch(AuthService.login(credentials)).catch((err) => {
            const errors = err.errors;
            const response = {
                error: true,
                message: errors,
            };
            this.setState({ response });
            this.setState({ loading: false });
        });
    }

    changeUsername = (uname) => {
        this.setState({ username: uname });
    }
    changePassword = (pswd) => {
        this.setState({ password: pswd });
    }

    render() {
        // If user is already authenticated we redirect to entry location.
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            return <Redirect to='/' />;
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonCard>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="2"></IonCol>
                                <IonCol><img src={logo} alt="carpetcall logo" width="100%"/></IonCol>
                                <IonCol size="2"></IonCol>
                            </IonRow>
                        </IonGrid>
                   
                        <form onSubmit={(e) => this.login(e)} method="post">
                            <IonItem>
                                <IonLabel position="floating">Username</IonLabel>
                                <IonInput value={this.state.username} onIonChange={(e) => this.changeUsername(e.detail.value)}></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Password</IonLabel>
                                <IonInput type="password" value={this.state.password} onIonChange={(e) => this.changePassword(e.detail.value)}></IonInput>
                            </IonItem>
                            <IonButton type="submit" expand="full">Login</IonButton>
                        </form>
                    </IonCard>

                    <IonAlert
                        isOpen={this.state.response.error}
                        cssClass='my-custom-class'
                        header={'Error!'}
                        message={this.state.response.message}
                        buttons={[
                            {
                                text: 'Ok',
                                role: 'cancel',
                                cssClass: 'secondary',

                            },
                        ]}
                    />
                </IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={this.state.loading}
                    message={'Please wait...'}
                    duration={5000}
                />
            </IonPage>
        )
    }
}

Login.defaultProps = {
    location: {
        state: {
            pathname: '/',
        },
    },
};

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            pathname: PropTypes.string,
        }),
    }),
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(Login);