import * as ActionTypes from '../action-types';

const initialState={
    searchCriteria:{}
}

const searchCriteria=(state,payload)=>{
    if(payload){
        localStorage.setItem('searchCriteriaC',JSON.stringify(payload));
    }
    const stateObj={
        ...state,
        searchCriteria:payload
    }
    return stateObj;
}

const carpetDataReducer=(state=initialState,{type,payload=null})=>{
    switch(type){
        case ActionTypes.SEARCH_CRITERIA_CARPET:
            return searchCriteria(state,payload);
        default:
            return state;
    }
}

export default carpetDataReducer;