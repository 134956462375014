import React from "react";
import { IonContent, IonHeader, IonIcon, IonTitle, IonToolbar, IonItem, IonLabel, IonList, IonNote, IonListHeader } from '@ionic/react';
import { closeCircleOutline } from "ionicons/icons";
import BackButton from "../BackButton";

const Committals = (props) => {
    const committalData = props.committalData;
    const stockCode = props.stockCode;
    const backUrl = '/search-results';

    let committals = null;
    if (committalData.length > 0) {
        committals = committalData.map(el => {
            return (
                <IonList key={el.id}>
                    <IonListHeader>Job#{el.jobNumber}</IonListHeader>
                    <IonItem>
                        <IonLabel>Lay date</IonLabel>
                        <h4 slot="end">{el.layDate}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Lay code</IonLabel>
                        <h4 slot="end">{el.layCode}</h4>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Metres committed</IonLabel>
                        <h4 slot="end">{el.metresCommitted}</h4>
                    </IonItem>
                </IonList>
            )
        })
    }
    else {
        committals = (
            <ion-text color="danger">
                <p className="text-center"><IonIcon icon={closeCircleOutline}/> COMMITTALS NOT AVAILABLE</p>
            </ion-text>
        )
    }
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <BackButton {...props} backUrl={backUrl} />
                    <IonTitle>{stockCode} - Committals</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {committals}
                <br/>
                <br/>
                <br/>
            </IonContent>
        </>
    )
}

export default Committals;