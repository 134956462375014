import React from "react";
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';
import BackButton from '../BackButton';

const Summary = (props) => {
    const summaryData = props.summaryData;
    const stockCode = props.stockCode;
    const backUrl = '/search-results';

    let stockSummary = null;
    const labelColor=(labelText)=>{
        if(labelText=='Stock Avail'){
            return 'primary'
        }
        else if(labelText=='***Discontinued***'){
            return 'danger'
        }
        else{
            return null;
        }
    }
    if (summaryData.length > 0) {
        stockSummary = summaryData.map(el => {
            return (
                <IonItem key={el.id}>
                    <IonLabel color={labelColor(el.k)}>{el.k}</IonLabel>
                    <h4 slot="end">{el.v}</h4>
                </IonItem>
            )
        })
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <BackButton {...props} backUrl={backUrl} />
                    <IonTitle>{stockCode} - Summary</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {stockSummary}
                </IonList>
            </IonContent>
        </>
    )
}

export default Summary;