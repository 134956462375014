import React, { Component } from "react";
import { IonContent, IonHeader, IonPage, IonLoading, IonTitle, IonToolbar, IonNote, IonItem, IonLabel, IonList, IonListHeader, IonButtons, IonBackButton, IonRefresherContent, IonRefresher } from '@ionic/react';
import { RefresherEventDetail } from "@ionic/core";
import Http from '../Http';

class RugBay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bays: [],
            loading: false
        }
        this.stateCode = this.props.match.params.state;
        this.stockCode = this.props.match.params.stockCode;
        this.stockDetails = this.props.location.state;
    }

    componentDidMount() {
        this.getBayInfo();
    }
    getBayInfo = (event = null) => {
        if (!event) {
            this.setState({ loading: true });
        }

        Http.get('rug-bays.php?stock-code=' + this.stockCode + '&state=' + this.stateCode).then(res => {
            if (res.data) {
                this.setState({ bays: res.data }, () => {
                    if (event) {
                        event.detail.complete();
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            if (!event) {
                this.setState({ loading: false });
            }
        })
    }

    handleBtnBack = (e) => {
        this.props.history.push({ pathname: '/rug-details/' + this.stateCode + '/' + this.stockCode, state: this.stockDetails });
    }

    render() {
        const { bays, loading } = this.state;
        let bayData = null;
        if (bays.length > 0) {
            bayData = bays.map(el => {
                return (
                    <IonItem key={el.id}>
                        <IonLabel><h2>{el.k}</h2></IonLabel>
                        <IonNote slot="end" color={el.v <= 0 ? 'danger' : null}>
                            <h2 className={el.k === 'Total' ? 'text-bold' : null}>{el.v}</h2>
                        </IonNote>
                    </IonItem>
                )
            });
        } else {
            bayData = (
                <IonItem>
                    <IonLabel color="danger">DATA UNAVAILABLE</IonLabel>
                </IonItem>
            )
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton onClick={(e) => this.handleBtnBack(e)} defaultHref="/" />
                        </IonButtons>
                        <IonTitle>{this.stockDetails.name + ' ' + this.stockDetails.colour}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonRefresher slot="fixed" onIonRefresh={(e) => this.getBayInfo(e)}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <IonList>
                        <IonListHeader>Rug Bays</IonListHeader>
                        {bayData}
                    </IonList>
                    <p className="text-center"><small>Swipe down to refresh content</small></p>
                </IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={this.state.loading}
                    message={'Please wait...'}
                />
            </IonPage>
        );
    }
}

export default RugBay;