import React, { Component } from "react";
import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonLoading, IonButtons, IonBackButton } from '@ionic/react';
import { star } from "ionicons/icons";
import Http from '../Http';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";


class RugDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allRugs: [],
            loading: false,
        }
        this.stateCode = this.props.match.params.state;
        this.stockCode = this.props.match.params.stockCode;
        this.stockDetails = this.props.location.state;
    }

    componentDidMount() {
        this.getData();
    }
    getData = () => {
        this.setState({ loading: true });
        Http.get('rug-view.php?stock-code=' + this.stockCode + '&state=' + this.stateCode).then(res => {
            if (res.data && res.data.success) {
                this.setState({ allRugs: res.data.items })
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.setState({ loading: false });
        })
    }
    handleBtnBack = () => {
        this.props.history.push({ pathname: '/rug-search-results', state: this.props.searchCriteria });
    }
    render() {
        let results = null;
        if (this.state.allRugs.length > 0) {
            results = this.state.allRugs.map(el => {
                return (
                    <Link  key={el.code + el.colour} to={{ pathname: `/rug-bay/${this.stateCode ? this.stateCode : 'L'}/${el.code}`, state: this.stockDetails }}>
                        <IonItem>
                            <IonLabel>
                                <h2>{el.code}</h2>
                                <h1>{el.name}</h1>
                                <h2>COLOUR: {el.colour} <IonIcon icon={star}></IonIcon> SIZE: {el.size}</h2>
                            </IonLabel>
                        </IonItem>
                    </Link>
                )
            })
        } else {
            results = (
                <IonItem>
                    <IonLabel color="danger" className="text-center">
                        DATA UNAVAILABLE
                    </IonLabel>
                </IonItem>
            )
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton onClick={() => this.handleBtnBack()} defaultHref="/" />
                        </IonButtons>
                        <IonTitle>{this.stockDetails.name + ' ' + this.stockDetails.colour}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {results}
                    </IonList>

                </IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={this.state.loading}
                    message={'Please wait...'}
                />
            </IonPage >
        );
    }
}
const mapStateToProps = state => {
    return {
        searchCriteria: state.rugDataReducer.searchCriteria
    }
}

export default connect(mapStateToProps)(RugDetails);