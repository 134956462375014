import React from 'react';
import { IonButtons, IonBackButton } from '@ionic/react';
import { connect } from "react-redux";

const BackButton = (props) => {
    const searchCriteria=props.searchCriteria;

    const handleButtonClick = () => {
        if(props.backUrl){
            props.history.push({
                pathname:props.backUrl,
                state:searchCriteria
            });
        }else{
            props.history.push('/');
        }
    }
    return (
        <IonButtons slot="start">
            <IonBackButton onClick={handleButtonClick} defaultHref="/" />
        </IonButtons>
    );
}

const mapStateToProps=state=>{
  
    return {
        searchCriteria:state.carpetDataReducer.searchCriteria
    }
};
export default connect(mapStateToProps)(BackButton);