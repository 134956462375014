import React, { Component } from "react";
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet, IonLoading } from '@ionic/react';
import { listCircleOutline, informationCircle, cartOutline, apertureOutline } from 'ionicons/icons';
import { Route } from 'react-router-dom';
import Summary from '../components/StockDetails/Summary';
import Rolls from '../components/StockDetails/Rolls';
import Committals from "../components/StockDetails/Committals";
import Orders from "../components/StockDetails/Orders";
import Http from "../Http";


class StockDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: [],
            rolls: [],
            committals: [],
            orders: [],
            loadingData: false,
        }
        this.stateCode = this.props.match.params.state;
        this.stockCode = this.props.match.params.stockCode;
        this.stockParams = '/' + this.stateCode + '/' + this.stockCode;

    }
    componentDidMount() {
        let dataToLoad=this.props.match.params.curTab=='summary'?'product':this.props.match.params.curTab
        if(dataToLoad){
            this.getData(dataToLoad, this.props.match.params.curTab);
        } else{
            this.getData('product','summary');
        }
    }
    getData = (endPoint, initialTab = false) => {
        this.setState({ loadingData: true });
        let params = new URLSearchParams();
        params.append('state', this.stateCode);
        params.append('stock-code', this.stockCode);
        Http.post(endPoint + '.php', params).then(res => {
            if (res.data) {
               
                if (endPoint === 'committals') {
                    if (res.data.items && res.data.items.length > 0) {
                        this.setState({ [endPoint]: res.data.items }, () => {
                            if (initialTab) {
                                this.changeTab(initialTab);
                            }
                        })
                    }

                } else {
                    this.setState({ [endPoint]: res.data }, () => {
                        if (initialTab) {
                            this.changeTab(initialTab);
                        }
                    })
                }

            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.setState({ loadingData: false });
        })
    }

    changeTab = (tabName) => {
        document.getElementById('tab-button-'+tabName).click();
    }


    loadDataForTab = (e) => {
        let availableTabs = ['summary', 'rolls', 'committals', 'orders'];
        if (e.detail && e.detail.tab !== '') {
            let tabName = e.detail.tab;
            if (availableTabs.includes(tabName)) {
                if (tabName === 'summary') {
                    tabName = 'product';
                }
                let endPoint = tabName;
                this.getData(endPoint);
            }

        }
    }


    render() {

        return (
            <>
                <IonTabs onIonTabsWillChange={(e) => this.loadDataForTab(e)}>
                    <IonRouterOutlet>
                        <Route path="/app/stock-details/summary/:state/:stockCode" render={(props) => <Summary {...props} stockCode={this.stockCode} summaryData={this.state.product} />} exact />
                        <Route path="/app/stock-details/rolls/:state/:stockCode" render={(props) => <Rolls stockCode={this.stockCode} {...props} rollData={this.state.rolls} />} exact={true} />
                        <Route path="/app/stock-details/committals/:state/:stockCode" render={(props) => <Committals stockCode={this.stockCode} {...props} committalData={this.state.committals} />} exact={true} />
                        <Route path="/app/stock-details/orders/:state/:stockCode" render={(props) => <Orders stockCode={this.stockCode} {...props} orderData={this.state.orders} />} exact={true} />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                        <IonTabButton tab="summary" href={'/app/stock-details/summary' + this.stockParams}>
                            <IonIcon icon={listCircleOutline} />
                            <IonLabel>Summary</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="rolls" href={'/app/stock-details/rolls' + this.stockParams}>
                            <IonIcon icon={apertureOutline} />
                            <IonLabel>Rolls</IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="committals" href={'/app/stock-details/committals' + this.stockParams}>
                            <IonIcon icon={informationCircle} />
                            <IonLabel>Committals</IonLabel >
                        </IonTabButton>

                        <IonTabButton tab="orders" href={'/app/stock-details/orders' + this.stockParams}>
                            <IonIcon icon={cartOutline} />
                            <IonLabel>Orders</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={this.state.loadingData}
                    message={'Please wait...'}

                />
            </>
        )
    }
}

export default StockDetails;