import Http from '../Http';
import * as action from '../store/actions';

export function login(credentials) {
  return (dispatch) => new Promise((resolve, reject) => {
    //Preparing the headers for basic auth
    //const authHeaders={headers:{Authorization:'Basic '+btoa(credentials.email+':'+credentials.password)}};
    let params = new URLSearchParams();
    params.append('Username', credentials.username);
    params.append('Password', credentials.password);
    Http.post('login.php', params)
      .then((res) => {
        if (res.data.success) {
          dispatch(action.authLogin(res.data));
          return resolve();
        }
        else {
          const { success: status, msg: errors } = res.data;
          const data = {
            status,
            errors,
          };
          return reject(data);
        }

      })
      .catch((err) => {
        console.log('Validaton error.')
      });
  });
}


