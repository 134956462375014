import axios from 'axios';
import * as env from './env';

axios.defaults.baseURL= env.API_END_POINT;


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('Unauthorized');
    }
    return Promise.reject(error);
  },
);

export default axios;
