import React, { Component } from "react";
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonLoading, IonButtons, IonBackButton } from '@ionic/react';
import { star } from "ionicons/icons";
import Http from '../Http';
import { Link } from 'react-router-dom';


class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: 0,
            limit: 20, //number of results per request
            loading: false,
            results: [],
            totalResults: 0,
        }
        this.searchParms = this.props.location.state
    }
    componentDidMount() {
        if (!Object.keys(this.searchParms).length) {
            this.searchParms = JSON.parse(localStorage.getItem('searchCriteriaC'));
        }
        this.loadProducts();
    }

    loadProducts = (e = null) => {
        let params = new URLSearchParams();
        params.append('state', this.searchParms.stateName);
        params.append('stock-code', this.searchParms.stockCode);
        params.append('product-name', this.searchParms.productName);
        params.append('colour', this.searchParms.colour);
        params.append('start', this.state.start);
        params.append('limit', this.state.limit);

        if (!e) {
            this.setState({ loading: true })
        }
        Http.post('search.php', params).then(res => {
            if (res.data.success && res.data.items) {
                let { start, limit, loading, results, total } = this.state;
                let newData = res.data.items;
                newData.forEach(dta => {
                    results.push(dta);
                });
                this.setState({ results: results, totalResults: res.data.total });
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            if (e) {
                e.target.complete();
            } else {
                this.setState({ loading: false });
            }

        })
    }

    loadMore = (e) => {
        this.setState(prevState => {
            return {
                start: prevState.start + prevState.limit
            }
        }, () => {
            this.loadProducts(e)
        });
    }

    handleBtnBack = () => {
        this.props.history.push('/carpet-search');
    }

    render() {
        let results = null;
        if (this.state.results.length > 0) {
            results = this.state.results.map((el, index) => {
                return (
                    <Link key={el.code + el.name + el.rrp} to={{ pathname: `/stock-details/summary/${this.searchParms.stateName ? this.searchParms.stateName : 'L'}/${el.code}` }}>
                        <IonItem >
                            <IonLabel>
                                <h2>{el.code}</h2>
                                <h1>{el.name}</h1>
                                <h2>COLOUR: {el.colour} <IonIcon icon={star}></IonIcon>{' '+ el.rrp}</h2>
                            </IonLabel>
                        </IonItem>
                    </Link>

                )
            })
        } else {
            results = (
                <IonItem>
                    <IonLabel color="danger"><h2 className="text-center">Product not found. <br />Please change your search criteria.</h2></IonLabel>
                </IonItem>
            )
        }

        let infiniteScrolling = null;
        if (this.state.totalResults > this.state.limit && this.state.results.length <= this.state.totalResults) {
            infiniteScrolling = (
                <IonInfiniteScroll threshold="150px" onIonInfinite={(e) => this.loadMore(e)}>
                    <IonInfiniteScrollContent loadingSpinner="crescent" loadingText="Loading more results">

                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            )
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton onClick={() => this.handleBtnBack()} defaultHref="/" />
                        </IonButtons>
                        <IonTitle>Search Results</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {results}
                    </IonList>
                    {infiniteScrolling}
                </IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={this.state.loading}
                    message={'Please wait...'}
                />
            </IonPage >
        )
    }
}

export default SearchResults;