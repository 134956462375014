import React, { Component } from "react";
import { IonContent,IonSpinner, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonButton, IonButtons, IonBackButton } from '@ionic/react';
import Http from '../Http';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionTypes from '../store/action-types/index';


class RugSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedStates: [],
            loadingStates:false,
            stateName: '',
            productName: '',
            stockCode: '',
            searchActive: false,
        }
    }

    componentDidMount() {
        this.setState({loadingStates:true});
        let params=new URLSearchParams();
        params.append('role',this.props.userRole);
        Http.post('states.php',params).then(res => {
            if (res.data) {
                this.setState({ allowedStates: res.data.items })
                if(res.data.items.length===1){
                    this.setState({stateName:res.data.items[0].value});
                }
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            this.setState({ loadingStates: false });
        });;
        this.initializeState();
    }
    initializeState=()=>{
        let initialValue=this.props.searchCriteria;
        if(initialValue 
            && Object.keys(initialValue).length>0 
            && initialValue.constructor===Object)
            {
                Object.keys(initialValue).map((key)=>{
                    this.setState({[key]:initialValue[key]});
                })
            }
    }
    searchRug= () => {
        this.setState({ searchActive: true });
        this.props.setSearchCriteria(this.state);
    }

    onChangeState = (stateName) => {
        this.setState({ stateName: stateName });
        this.props.setSearchCriteria(this.state);
    }
    onChangeRugCode = (stockCode) => {
        stockCode=stockCode.toUpperCase();
        this.setState({ stockCode: stockCode });
        this.props.setSearchCriteria(this.state);
    }
    onChangeProductName = (productName) => {
        productName=productName.toUpperCase();
        this.setState({ productName: productName });
        this.props.setSearchCriteria(this.state);
    }
    handleBtnBack=()=>{
        this.props.history.push('/')
    }
    render() {
        if(this.state.searchActive){
            return <Redirect to={{ pathname: '/rug-search-results', state: this.state }} />;
        }
        let stateVal = null;
        if (this.state.allowedStates.length > 0) {
            stateVal = this.state.allowedStates.map(el => {
                return (
                    <IonSelectOption key={el.value} value={el.value}>{el.name}</IonSelectOption>
                )
            })
        }

        let states = null;
        if (this.state.loadingStates) {
            states = <IonSpinner slot="end" name="crescent" />
        } else {
            if (this.state.allowedStates.length > 0) {
                states = (
                    <IonSelect placeholder={this.state.allowedStates.length>1?'Select State':null} slot="end" value={this.state.stateName} onIonChange={(e) => this.onChangeState(e.detail.value)}>
                        {stateVal}
                    </IonSelect>
                )
            }
        }
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton onClick={() => this.handleBtnBack()} defaultHref="/" />
                        </IonButtons>
                        <IonTitle>Rug Search</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonList>
                        <IonListHeader>
                            <IonLabel>
                                Rugs
                            </IonLabel>
                        </IonListHeader>
                        <IonItem>
                            <IonLabel>State</IonLabel>
                            {states}
                        </IonItem>
                        <IonItem>
                            <IonLabel>Rug Code</IonLabel>
                            <IonInput slot="end" value={this.state.stockCode} onIonChange={(e) => this.onChangeRugCode(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Product Name</IonLabel>
                            <IonInput slot="end" value={this.state.productName} onIonChange={(e) => this.onChangeProductName(e.detail.value)}></IonInput>
                        </IonItem>
                    </IonList>
                    <IonButton expand="block" onClick={() => this.searchRug()}>Search</IonButton>

                </IonContent>
            </IonPage>
        )
    }
}
const mapDispatchToProps=dispatch=>{
    return{
        setSearchCriteria:(searchCriteria)=>dispatch({type:actionTypes.SEARCH_CRITERIA_RUG,payload:searchCriteria})
    }
}
const mapStateToProps=state=>{
    return {
        searchCriteria:state.rugDataReducer.searchCriteria,
        userRole:state.Auth.user.name
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(RugSearch);